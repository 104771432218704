<template>
    <div>
        <wizard-stepper value="1"/>
        <template v-if="submit.status === OperationStatus.Succeeded">
            <h3 class="pa-9 text-center">
                Thank you! Please allow 48 hours for our team to review. You will receive an e-mail with next steps. If you have any questions, please contact support@getgrab.
            </h3>
        </template>
        <template v-else>
            <v-dialog
                v-model="discardConfirmationVisibility"
                max-width="290"
            >
                <v-card>
                    <v-card-title class="text-h5">
                        Are you sure?
                    </v-card-title>

                    <v-card-text>
                        You are about to discard the store, are you sure?
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            color="green darken-1"
                            text
                            @click="discardStore()"
                        >
                            Yes
                        </v-btn>

                        <v-btn
                            color="green darken-1"
                            text
                            @click="discardConfirmationVisibility = false"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-container fluid>
                <v-row>
                    <v-col md="8">
                        <v-banner class="font-weight-bold">
                            Create store
                        </v-banner>
                        <v-form
                            ref="form"
                            v-model="valid"
                        >
                            <v-expansion-panels
                                multiple
                                v-model="expandedPanels"
                            >
                                <store-information-section
                                    v-model="storeInformation"
                                />
                                <pos-section
                                    v-model="pos"
                                />
                            </v-expansion-panels>
                        </v-form>
                    </v-col>
                    <v-col md="4 pt-5">
                        <div class="text-center pt-3" v-if="savingDraft">
                            Saving changes...
                        </div>
                        <div class="text-center pt-3">
                            <v-btn
                                large
                                color="primary"
                                @click="goToNextStep()"
                            >
                                Continue to Design
                            </v-btn>
                        </div>
                        <div class="text-center pt-3">
                            <v-btn
                                @click="discardConfirmationVisibility = true"
                                small
                                color="error"
                                outlined
                            >
                                Discard store
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </div>
</template>

<script>

import WizardStepper from '../wizard_stepper';
import StoreInformationSection from './store_information_section';
import PosSection from './pos_section';
import {OperationStatus} from "helpers/common";

export default {
    name: "create_store_step",
    components: {
        WizardStepper,
        StoreInformationSection,
        PosSection,
    },
    computed: {
        OperationStatus: () => OperationStatus,
    },
    data() {
        return {
            valid: true,
            savingDraft: false,
            expandedPanels: [0, 1],
            discardConfirmationVisibility: false,
            submit: {
                status: OperationStatus.Unknown
            },
            storeInformation: {
                name: null,
                country: null,
                location: null,
                region: null,
                language: null,
                airportCode: null,
                airportTerminal: null,
                streetLocation: null,
                category: null,
                foodCategory: null,
                serviceCategory: null,
                retailCategory: null,
                currency: null,
                revenueCenterID: null,
                grabApiTenderId: null,
                grabApiEmployeeId: null,
                menuPriceLevel: null,
            },
            pos: {
                isIntegrated: null,
                hmshost: {
                    subscriptionKey: null,
                    clientID: null,
                    username: null,
                    passKey: null,
                    revenueCenterID: null,
                    propertyID: null,
                    baseURL: null,
                    offlineURL: null,
                    tenderType: null,
                    orderTypeIDRef: null,
                },
                omnivore: {
                    locationID: null,
                    micros3700: {
                        menuPriceLevel: null,
                    },
                    ncrAloha: {
                        forcedModifiers: false,
                    },
                },
                agilysis: {
                    authCode: null,
                    clientID: null,
                    soapURL: null,
                    tenderID: null,
                    checkTypeID: null,
                    employeeID: null,
                    priceLevel: null,
                    profitCentreID: null,
                },
            },

        };
    },
    methods: {
        goToNextStep() {
            const formIsValid = this.$refs.form.validate();
            if (!formIsValid) {
                this.toast("Please fill all required fields.", "error");
                return;
            }
            this.submit.status = OperationStatus.Succeeded;
        },
        discardStore() {
            this.$router.push({ path: '/' });
        },
    }
}
</script>

<style scoped lang="scss">
    @import "../section";
</style>
