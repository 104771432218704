var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("wizard-stepper", { attrs: { value: "1" } }),
      _vm.submit.status === _vm.OperationStatus.Succeeded
        ? [
            _c("h3", { staticClass: "pa-9 text-center" }, [
              _vm._v(
                " Thank you! Please allow 48 hours for our team to review. You will receive an e-mail with next steps. If you have any questions, please contact support@getgrab. "
              ),
            ]),
          ]
        : [
            _c(
              "v-dialog",
              {
                attrs: { "max-width": "290" },
                model: {
                  value: _vm.discardConfirmationVisibility,
                  callback: function ($$v) {
                    _vm.discardConfirmationVisibility = $$v
                  },
                  expression: "discardConfirmationVisibility",
                },
              },
              [
                _c(
                  "v-card",
                  [
                    _c("v-card-title", { staticClass: "text-h5" }, [
                      _vm._v(" Are you sure? "),
                    ]),
                    _c("v-card-text", [
                      _vm._v(
                        " You are about to discard the store, are you sure? "
                      ),
                    ]),
                    _c(
                      "v-card-actions",
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "green darken-1", text: "" },
                            on: {
                              click: function ($event) {
                                return _vm.discardStore()
                              },
                            },
                          },
                          [_vm._v(" Yes ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "green darken-1", text: "" },
                            on: {
                              click: function ($event) {
                                _vm.discardConfirmationVisibility = false
                              },
                            },
                          },
                          [_vm._v(" Cancel ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-container",
              { attrs: { fluid: "" } },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { md: "8" } },
                      [
                        _c("v-banner", { staticClass: "font-weight-bold" }, [
                          _vm._v(" Create store "),
                        ]),
                        _c(
                          "v-form",
                          {
                            ref: "form",
                            model: {
                              value: _vm.valid,
                              callback: function ($$v) {
                                _vm.valid = $$v
                              },
                              expression: "valid",
                            },
                          },
                          [
                            _c(
                              "v-expansion-panels",
                              {
                                attrs: { multiple: "" },
                                model: {
                                  value: _vm.expandedPanels,
                                  callback: function ($$v) {
                                    _vm.expandedPanels = $$v
                                  },
                                  expression: "expandedPanels",
                                },
                              },
                              [
                                _c("store-information-section", {
                                  model: {
                                    value: _vm.storeInformation,
                                    callback: function ($$v) {
                                      _vm.storeInformation = $$v
                                    },
                                    expression: "storeInformation",
                                  },
                                }),
                                _c("pos-section", {
                                  model: {
                                    value: _vm.pos,
                                    callback: function ($$v) {
                                      _vm.pos = $$v
                                    },
                                    expression: "pos",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-col", { attrs: { md: "4 pt-5" } }, [
                      _vm.savingDraft
                        ? _c("div", { staticClass: "text-center pt-3" }, [
                            _vm._v(" Saving changes... "),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "text-center pt-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { large: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.goToNextStep()
                                },
                              },
                            },
                            [_vm._v(" Continue to Design ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "text-center pt-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                small: "",
                                color: "error",
                                outlined: "",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.discardConfirmationVisibility = true
                                },
                              },
                            },
                            [_vm._v(" Discard store ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }